<template>
  <el-dialog custom-class="custom-dialog" :title="dialogTitle" :visible.sync="dialogVisible"
    :close-on-click-modal="false" width="1400px" top="0" @close="closeDialog">
    <div class="scrollElement">
      <div class="header">
        <div class="header-item">
          <span>电梯名称:</span>
          <el-input placeholder="请输入内容" v-model="name" clearable>
          </el-input>
        </div>
        <div class="header-item">
          <span>电梯注册代码:</span>
          <el-input placeholder="请输入内容" v-model="code" clearable>
          </el-input>
        </div>
        <div class="header-item">
          <span>检验类型:</span>
          <el-select v-loading="statusTypeLoading" v-model="type" clearable filterable placeholder="请选择">
            <el-option v-for="item in statusList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="header-item">
          <span>检验时间:</span>
          <el-date-picker v-model="allTime" type="daterange" range-separator="至" start-placeholder="开始日期"
            end-placeholder="结束日期" style="width: 218px;">
          </el-date-picker>
        </div>


        <el-button type="primary" @click="getTable">查询</el-button>
        <el-button type="warning" @click="reset">重置</el-button>
      </div>

      <div>
        <el-table v-loading="loading" :data="tableData" stripe ref="multipleTable" height="500px" style="width: 100%">
          <el-table-column type="index" label="序号" width="50px" />
          <el-table-column align="center" prop="code" label="电梯注册代码" width="150px" />
          <el-table-column align="center" prop="name" label="电梯名称" min-width="200px" />
          <el-table-column align="center" prop="orgName" label="检验单位" width="150px" />
          <el-table-column align="center" prop="type" label="检验类型" width="150px">
            <template slot-scope="scope">
              <span>{{getStatusText(statusList,scope.row.type)}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="detectionTime" label="检验时间" width="150px" />
          <el-table-column align="center" prop="conclusion" label="检验结论" width="150px">
            <template slot-scope="scope">
              <span>{{getStatusText(paymentstatus,scope.row.conclusion)}}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column fixed="right" align="center" prop="address" label="操作" width="80px">
						<template slot-scope="scope">
							<el-button @click="check(scope.row)" type="primary" size="small">查看</el-button>
						</template>
					</el-table-column> -->
        </el-table>
        <Pagination :total="total" :page.sync="current" :limit.sync="size" @pagination="dictionarPage" />
      </div>
    </div>
    <div class="null"></div>


  </el-dialog>
</template>
<script>
  import Pagination from '@/components/Pagination'
  import ElevatorWarningDetail from './elevator-warning-detail.vue'
  import {
    formatDate,
    getTimeSE
  } from "@/util";
  let current_time = formatDate(new Date(), "yyyy-MM-dd") + " 00:00:00";
  let current_time1 = new Date(current_time).getTime();
  let starTime = new Date(current_time1 - 29 * 24 * 60 * 60 * 1000);
  let endTime = new Date(current_time1);
  export default {
    props: {
      dialogTitle: String,
      start: Number | String,
      end: Number | String,
      openType: Number | String,
    },
    components: {
      Pagination,
      ElevatorWarningDetail
    },
    data() {
      return {
        dialogVisible: true,
        loading: false,
        //表格筛选信息
        name: '',
        code: '',
        type: '',
        allTime: [starTime, endTime],
        statusTypeLoading: true,
        //表格数据及分页选项
        tableData: [],
        total: 0,
        size: 10,
        current: 1,

        //检验类型字典
        statusList: [],
        paymentstatus: [],
      }
    },
    computed: {
      params() {
        return {
          current: this.current,
          size: this.size,
          name: this.name,
          code: this.code,
          type: this.type,
          start: this.start,
          end: this.end
        }
      }
    },
    methods: {
      closeDialog() {
        this.$emit('closeDialog', {
          openType: this.openType
        })
      },
      reset() {
        this.name = ''
        this.code = ''
        this.type = ''
        this.allTime = [starTime, endTime]
        this.getTable()
      },
      // 分页
      dictionarPage(data) {
        this.current = data.page
        this.size = data.limit
        this.getTable()
      },
      //获取状态字典
      async getDictionary(codes = ['DETECTION_TYPE', 'DETECTION_RESULT']) {
        const res = await this.$http.post(`/api/system/web/dict/dictionary?codes=${codes}`)
        if (res.status == 200 && res.data.code == 200) {
          const {
            data: {
              data
            },
          } = res
          this.statusList = data.DETECTION_TYPE
          this.paymentstatus = data.DETECTION_RESULT
        }
        this.statusTypeLoading = false
      },
      getStatusText(arr, status) {
        const list = arr.filter(item => item.value == status)
        return list.length > 0 ? list[0].label : status
      },
      getTable() {
        if (this.allTime && this.allTime.length > 0) {
          this.tableData = []
          let atime = JSON.parse(JSON.stringify(this.allTime))
          this.params.start = new Date(atime[0]).getTime()
          this.params.end = new Date(atime[1]).getTime()
        }
        this.loading = true
        this.$http.post('/api/ele/web/detection/getBigDataEleList', this.params).then((res) => {
          if (res.status == 200 && res.data.code == 200) {
            this.tableData = res.data.data.records.map((qs) => {
              // qs.sosTypeName = this.getStatusText(qs.sosType)
              // qs.sosStatusName = this.getSosStatusText(qs.sosStatus)
              return qs
            })
            this.total = res.data.data.total
            this.$nextTick(() => {
              if (this.total > 0 && this.$refs.multipleTable) {
                this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
              }
            })
          }
          this.loading = false
        })
      },
      // 查看
      check(item) {


      },

    },
    created() {
      this.getDictionary()
        .then(() => {
          this.getTable()
        })
    }
  }
</script>
<style lang="scss" scoped>
  .scrollElement {
    margin: 20px 0 0 0;
    // height: 72vh;
    // overflow-y: scroll;
    padding-right: 20px
  }

  .null {
    height: 90px;
  }

  .header,
  .header-item {
    display: flex;
  }

  .header {
    flex-wrap: wrap;
    align-items: flex-start;
    margin-bottom: 20px;
  }

  .header-item {
    margin: 0 20px 20px 0;
    align-items: center;

    span {
      white-space: nowrap;
      margin-right: 8px;
    }
  }

  .scrollElement /deep/ .el-table__fixed-right {
    background: rgba(0, 0, 0, 0) !important;
  }

  .scrollElement /deep/ .el-table__fixed-right>>>.el-table__row>>>.el-table__cell:last-child {
    background: #1e2d4f;
  }
</style>
