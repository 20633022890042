<template>
  <el-dialog custom-class="custom-dialog" :title="dialogTitle" :visible.sync="dialogVisible"
    :close-on-click-modal="false" width="1400px" top="0" @close="closeDialog">
    <div class="scrollElement">
      <div class="header">
        <div class="header-item">
          <span>电梯名称:</span>
          <el-input placeholder="请输入内容" v-model="name" clearable>
          </el-input>
        </div>
        <div class="header-item">
          <span>电梯注册代码:</span>
          <el-input placeholder="请输入内容" v-model="code" clearable>
          </el-input>
        </div>
        <div v-if="!status" class="header-item">
          <span>状态:</span>
          <el-select v-model="taskStatus" filterable placeholder="请选择">
            <el-option v-for="item in taskStatusOptions" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="header-item">
          <span>故障描述:</span>
          <el-input placeholder="请输入内容" v-model="failureDesc" clearable>
          </el-input>
        </div>
        <div class="header-item">
          <span>报告时间:</span>
          <el-date-picker v-model="searchFailureTime" type="daterange" range-separator="至" start-placeholder="开始日期"
            end-placeholder="结束日期" style="width: 218px;" value-format="yyyy-MM-dd HH:mm:ss">
          </el-date-picker>
        </div>

        <el-button type="primary" @click="getTable">查询</el-button>
        <el-button type="warning" @click="reset">重置</el-button>
      </div>

      <div>
        <el-table v-loading="loading" height="400px" :data="tableData" ref="multipleTable" stripe style="width: 100%;">
          <el-table-column type="index" label="序号" width="50px" />
          <el-table-column align="center" prop="eleCode" label="电梯注册代码" width="150px" />
          <el-table-column align="center" prop="eleName" label="电梯名称" min-width="200px" />
          <el-table-column align="center" prop="failureDesc" label="故障描述" width="150px" />
          <el-table-column align="center" prop="status" label="处理状态" width="150px">
            <template slot-scope="scope">
              {{getStatusText(scope.row.status)}}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="failureTime" label="报告时间" width="150px" />
          <el-table-column align="center" prop="updateTime" label="更新时间" width="150px" />
          <el-table-column fixed="right" align="center" prop="address" label="操作" width="80px">
            <template slot-scope="scope">
              <el-button @click="check(scope.row)" type="primary" size="small">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
        <Pagination :total="total" :page.sync="current" :limit.sync="size" @pagination="dictionarPage" />
      </div>
    </div>

    <repair-detail v-if="repairDetailVisible" ref="repairDetail" :dioDetailsItem="dioDetailsItem" :flowList="flowList"
      @closeDialog="repairDetailVisible=false" />
  </el-dialog>
</template>
<script>
  import Pagination from '@/components/Pagination'
  import RepairDetail from './repair-detail'
  import {
    formatDate,
    getTimeSE
  } from "@/util";
  let current_time = formatDate(new Date(), "yyyy-MM-dd") + " 00:00:00";
  let current_time1 = new Date(current_time).getTime();
  let starTime = new Date(current_time1 - 29 * 24 * 60 * 60 * 1000);
  let endTime = new Date(current_time1);
  export default {
    components: {
      Pagination,
      RepairDetail
    },
    props: {
      dialogTitle: String,
      start: Number | String,
      end: Number | String,
      status: Number | String,
      openType: Number | String,
    },
    data() {
      return {
        dialogVisible: true,
        loading: false,
        searchFailureTime: [starTime, endTime],
        //表格筛选信息
        name: '',
        code: '',
        taskStatus: this.status,
        failureDesc: '',
        taskStatusOptions: [{
            value: 1,
            label: '待处理'
          },
          {
            value: 2,
            label: '进行中'
          },
          {
            value: 3,
            label: '已完成'
          },
          {
            value: 4,
            label: '待审核'
          },
        ],
        //表格数据及分页选项
        tableData: [],
        total: 0,
        size: 10,
        current: 1,
        //故障详情
        repairDetailVisible: false,
        dioDetailsItem: {},
        flowList: [],

        //故障状态字典
        statusList: [],


      }
    },
    computed: {
      params() {
        return {
          current: this.current,
          size: this.size,
          eleName: this.name,
          eleCode: this.code,
          taskStatus: this.taskStatus,
          failureDesc: this.failureDesc,
          // start:this.start,
          // end:this.end,
          searchFailureTime: [this.start, this.end]
        }
      }
    },
    methods: {
      closeDialog() {
        this.$emit('closeDialog', {
          openType: this.openType
        })
      },
      reset() {
        this.name = ''
        this.code = ''
        this.marking = ''
        this.failureDesc = ''
        this.taskStatus = this.status
        this.searchFailureTime = [starTime, endTime]
        this.getTable()
      },
      // 分页
      dictionarPage(data) {
        this.current = data.page
        this.size = data.limit
        this.getTable()
      },
      //获取状态字典
      async getDictionary(code = 'FAILURE_STATUS') {
        const res = await this.$http.get(`/api/system/web/dict/dictionary?code=${code}`)
        if (res.status == 200 && res.data.code == 200) {
          this.statusList = res.data.data
        }
        this.getTable()
      },
      getStatusText(status) {
        const list = this.statusList.filter(item => item.dictKey == status)
        return list.length > 0 ? list[0].dictValue : status
      },
      getTable() {
        if (this.searchFailureTime && this.searchFailureTime.length > 0) {
          this.tableData = []
          let atime = JSON.parse(JSON.stringify(this.searchFailureTime))
          this.params.searchFailureTime[0] = new Date(atime[0]).getTime()
          this.params.searchFailureTime[1] = new Date(atime[1]).getTime() + 24 * 60 * 60 * 1000 - 1000
        }else{
          return this.$message.error('请选择报告时间')
        }
        this.loading = true
        this.$http.post('/api/ele/web/failureTask/getList', this.params).then((res) => {
          if (res.status == 200 && res.data.code == 200) {
            this.tableData = res.data.data.records
            this.total = res.data.data.total
            this.$nextTick(() => {
              if (this.total > 0 && this.$refs.multipleTable) {
                this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
              }
            })
          }
          this.loading = false
        })
      },
      // 查看
      check(item) {
        this.dioDetailsItem = item
        this.getDetail(item)
      },
      getDetail(item) {
        let data = {
          taskId: item.id,
        }
        let url = '/api/ele/web/failureTask/detail'
        this.$http.post(url, data).then((res) => {
          if (res.data.success) {
            // 排除新状态
            res.data.data.forEach((qs) => {
              if (qs.partsList && qs.partsList != '') {
                qs.partsList = qs.partsList !== '请选择' ? JSON.parse(qs.partsList) : qs.partsList
              }
              if (qs.afterServiceImgs && qs.afterServiceImgs != '') {
                qs.afterServiceImgs = JSON.parse(qs.afterServiceImgs)
              }
              if (qs.signServiceImgs && qs.signServiceImgs != '') {
                qs.signServiceImgs = JSON.parse(qs.signServiceImgs)
              }
            })
            this.flowList = res.data.data.filter((item) => item.status < 10)
            this.repairDetailVisible = true
            this.$nextTick(() => {
              this.$refs.repairDetail.getData()
            })
          } else {
            this.$message.error(res.data.msg)
          }
        })
      },
    },
    created() {
      this.getDictionary()
    }
  }
</script>
<style lang="scss" scoped>
  .scrollElement {
    margin: 20px 0 0 0;
    height: 72vh;
    // overflow-y: scroll;
    padding-right: 20px
  }

  .null {
    height: 90px;
  }

  .header,
  .header-item {
    display: flex;
  }

  .header {
    flex-wrap: wrap;
    align-items: flex-start;
    margin-bottom: 20px;
  }

  .header-item {
    margin: 0 20px 20px 0;
    align-items: center;

    span {
      white-space: nowrap;
      margin-right: 8px;
    }
  }

  .scrollElement /deep/ .el-table__fixed-right {
    background: rgba(0, 0, 0, 0) !important;
  }

  .scrollElement /deep/ .el-table__fixed-right>>>.el-table__row>>>.el-table__cell:last-child {
    background: #1e2d4f;
  }
</style>
